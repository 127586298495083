import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AddPlaylistsAPIModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccount from '../useAccount';

const useSpotifyPlaylists = () => {
  const { unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AddPlaylistsAPIModel> | null>(
    () => {
      if (!unhurdUser?.spotifyAccounts[0].id) return null;

      return `spotify-playlists-data-${!unhurdUser?.spotifyAccounts[0].id}`;
    },
    async () => {
      if (!unhurdUser?.spotifyAccounts[0].id) return null;

      return await PlaylistAPI.getUserSpotifyPlaylists({
        id: unhurdUser?.spotifyAccounts[0].id,
      });
    }
  );

  return {
    allSpotifyPlaylists: data?.data.playlists,
    connectedSpotifyPlaylists: data?.data.playlists.filter((item) => item.isConnected),
    unconnectedSpotifyPlaylists: data?.data.playlists.filter((item) => !item.isConnected),
    spotifyPlaylistsIsLoading: isLoading,
    spotifyPlaylistsError: error,
    refetchSpotifyPlaylists: mutate,
  };
};

export default useSpotifyPlaylists;
