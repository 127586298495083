import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ConnectedPlaylistStatsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useAccount from '../useAccount';

const usePlaylistStats = () => {
  const { unhurdAccountId, unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ConnectedPlaylistStatsModel> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `playlist-stats-data-${unhurdAccountId}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await MetricsAPI.getConnectedPlaylistStats({
        unhurdId: unhurdAccountId,
        auth0Id: unhurdUser?.userId,
      });
    }
  );

  return {
    connectedPlaylists: data?.data.connected,
    rejectedPlaylists: data?.data.rejected,
    acceptedPlaylists: data?.data.accepted,
    pendingPlaylists: data?.data.pending,
    playlistStatsIsLoading: isLoading,
    playlistStatsError: error,
    refetchPlaylistStats: mutate,
  };
};

export default usePlaylistStats;
