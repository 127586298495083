import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { UnhurdPlaylistAPIModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccount from '../useAccount';

const usePlaylists = ({
  pageNumber,
  sortBy,
  orderBy,
  status,
  pageSize = 20,
}: {
  pageNumber: number;
  sortBy: string;
  orderBy: string;
  status: string;
  pageSize?: number;
}) => {
  const { unhurdAccountId, unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<UnhurdPlaylistAPIModel> | null>(
    () => {
      if (!unhurdAccountId) return null;

      return `playlist-pitch-data-${unhurdAccountId}-${pageNumber}-${sortBy}-${orderBy}-${status}`;
    },
    async () => {
      if (!unhurdAccountId) return null;

      return await PlaylistAPI.getConnectedPlaylists({
        id: unhurdUser?.spotifyAccounts[0].id,
        pageSize: pageSize,
        status,
        sortBy,
        orderBy,
        pageNumber,
      });
    }
  );

  return {
    playlists: data?.data.items,
    totalPlaylists: data?.data.totalItems,
    totalPages: data?.data.totalPages,
    playlistsIsLoading: isLoading,
    playlistsError: error,
    refetchPlaylists: mutate,
  };
};

export default usePlaylists;
