import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AreAllPausedModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccount from '../useAccount';

const useAreAllPlaylistsPaused = () => {
  const { unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AreAllPausedModel> | null>(
    () => {
      if (!unhurdUser) return null;

      return `are-all-paused-data-${unhurdUser.id}`;
    },
    async () => {
      if (!unhurdUser) return null;

      return await PlaylistAPI.areAllPaused({
        id: unhurdUser.spotifyAccounts[0].id,
      });
    }
  );

  return {
    areAllPlaylistsPaused: data?.data,
    areAllPlaylistsPausedIsLoading: isLoading,
    areAllPlaylistsPausedError: error,
    refetchAreAllPlaylistsPaused: mutate,
  };
};

export default useAreAllPlaylistsPaused;
