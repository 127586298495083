import axios from 'axios';

import {
  AddPlaylistsAPIModel,
  AreAllPausedModel,
  PausePlaylistModel,
  SpotifyTrackModel,
  UnhurdPlaylist,
  UnhurdPlaylistAPIModel,
  ValidTrackPlacementModel,
} from '@/models/PlaylistInterface';

class PlaylistAPI {
  static getConnectedPlaylists = async ({
    id,
    pageSize,
    status,
    sortBy,
    orderBy,
    pageNumber,
  }: {
    id: string | undefined;
    pageSize: number;
    status: string | '';
    sortBy: string | 'pending-pitches';
    orderBy?: string | 'desc';
    pageNumber?: number | '';
  }) =>
    await axios.get<UnhurdPlaylistAPIModel>(
      `playlist/${id}/connected?pageSize=${pageSize}&status=${status}&sortBy=${sortBy}&orderBy=${orderBy}&pageNumber=${pageNumber}`
    );

  static getPlaylist = async ({ id, spotifyId }: { id: string; spotifyId?: string }) =>
    await axios.get<UnhurdPlaylist>(`playlist/${id}/${spotifyId}`);

  static addPlaylists = async (data: object) => await axios.post<UnhurdPlaylist>(`playlist/`, data);

  static getUserSpotifyPlaylists = async ({ id }: { id?: string }) =>
    await axios.get<AddPlaylistsAPIModel>(`playlist/onboarding/${id}?includeConnected=true`);

  static getTrackForPlaylistPlacement = async ({ id }: { id: string }) =>
    await axios.get<SpotifyTrackModel>(`playlist/${id}/serve-track`);

  static verifyTrackPlaylistPlacement = async ({
    playlistId,
    trackId,
    servedAt,
  }: {
    playlistId: string;
    trackId: string;
    servedAt?: string;
  }) =>
    await axios.get<ValidTrackPlacementModel>(
      `playlist/${playlistId}/${trackId}/placed-track?${servedAt ? `trackServed=${servedAt}` : ''}`
    );

  static pausePlaylist = async ({ id, data }: { id: string; data: PausePlaylistModel }) =>
    await axios.post<PausePlaylistModel>(`playlist/${id}/pause`, data);

  static pauseAllPlaylists = async ({ id, data }: { id: string; data: PausePlaylistModel }) =>
    await axios.post<PausePlaylistModel>(`playlist/curator/${id}/pause`, data);

  static areAllPaused = async ({ id }: { id: string }) =>
    await axios.get<AreAllPausedModel>(`playlist/curator/${id}/are-all-paused`);
}

export default PlaylistAPI;
