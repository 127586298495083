import React, { useCallback, useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Button, Icon } from '@mui/material';

import usePlaylistPitches from '@/hooks/pitches/usePlaylistPitches';

import { TrackPlayingIndex, UnhurdPlaylist } from '../../models/PlaylistInterface';
import { PitchTrackModel } from '../../models/TrackInterfaces';
import PitchesTable from '../tables/PitchesTable';
import Loading from '../utility/Loading';

interface IPlaylistProps {
  playlist: UnhurdPlaylist;
  expanded?: boolean;
  playingIndex?: TrackPlayingIndex;
  setMultipleSelectedTracks: (tracks: PitchTrackModel[], playlist: UnhurdPlaylist) => void;
  updateTracks: boolean;
}

type ContToken = {
  pageNumber: number;
  contToken: string;
};

export default function PlaylistAccordion({
  playlist,
  expanded,
  setMultipleSelectedTracks,
  updateTracks,
}: IPlaylistProps) {
  document.title = 'Playlist Pitches';
  const [tracks, setTracks] = useState<PitchTrackModel[]>([]);
  const [isTracksLoading, setIsTracksLoading] = useState<boolean>(true);
  const [selectedTracks, setSelectedTracks] = useState<PitchTrackModel[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [contTokens, setContTokens] = useState<ContToken[]>([{ pageNumber: 0, contToken: '' }]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const {
    playlistPitches,
    totalPlaylistPitches,
    playlistPitchesContToken,
    playlistPitchesIsLoading,
    refetchPlaylistPitches,
  } = usePlaylistPitches({
    playlistId: playlist?.platform.id || '',
    status: 'pending',
    contToken: contTokens[pageNumber]?.contToken,
    pageSize: 10,
  });

  const refetchAllTracks = useCallback(async () => {
    setTracks([]);
    setSelectedTracks([]);
    setIsTracksLoading(true);
    setPageNumber(0);
    setTimeout(async () => {
      await refetchPlaylistPitches();
      setIsTracksLoading(false);
    }, 500);
  }, [refetchPlaylistPitches]);

  useEffect(() => {
    refetchAllTracks();
  }, [refetchAllTracks, updateTracks]);

  useEffect(() => {
    if (playlistPitches && playlistPitches.length === 0 && !playlistPitchesIsLoading) {
      setIsTracksLoading(false);
    }
    if (playlistPitches && playlistPitches !== tracks && totalPlaylistPitches && !playlistPitchesIsLoading) {
      setTracks(playlistPitches);
      const total =
        totalPlaylistPitches % 10 === 0
          ? Math.floor(totalPlaylistPitches / 10)
          : Math.floor(totalPlaylistPitches / 10) + 1;
      setTotalPages(total);
      setIsTracksLoading(false);
    }
  }, [isTracksLoading, playlistPitches, playlistPitchesIsLoading, totalPlaylistPitches, tracks]);

  useEffect(() => {
    if (playlistPitchesContToken && pageNumber > 0) {
      if (contTokens.length > totalPages) return setIsTracksLoading(false);
      setContTokens([...contTokens, { pageNumber: pageNumber + 1, contToken: playlistPitchesContToken }]);
    }
  }, [contTokens, pageNumber, playlistPitchesContToken, totalPages]);

  useEffect(() => {
    if (selectedTracks.length > 0) {
      setMultipleSelectedTracks(selectedTracks, playlist);
    } else {
      setMultipleSelectedTracks([], playlist);
    }
  }, [playlist, selectedTracks, setMultipleSelectedTracks]);

  return (
    <div
      className={
        tracks.length > 0 && selectedTracks.length === tracks.length ? 'to-do-card-border mb16' : 'playlist-card mb16'
      }
    >
      <Accordion
        className={tracks.length > 0 && selectedTracks.length === tracks.length ? 'to-do-card' : 'p1'}
        defaultExpanded={expanded}
        onChange={(_event, value) => {
          if (value) {
            refetchAllTracks();
          }
        }}
      >
        <AccordionSummary
          expandIcon={
            <Icon className="accordion-expand-icon" data-testid="expand-accordion-button">
              expand_more
            </Icon>
          }
        >
          <div className="min-h150 d-flex w100p">
            <img
              className="playlist-image"
              src={playlist?.images?.length > 0 ? playlist?.images[0]?.url : '/images/logos/no-image-available.svg'}
              alt={''}
            />
            <div className="pl16 mt-auto mb-auto">
              <p className="text-faded mb16">{playlist.stats?.pendingPitches} pending pitches</p>
              <h2 className="mb16">{playlist.name}</h2>
              <div className="d-flex text-faded">
                <p>
                  Song Count: <span className="text-faded">{playlist.trackCount}</span>
                </p>
              </div>
            </div>
          </div>
        </AccordionSummary>
        {(isTracksLoading || playlistPitchesIsLoading) && <Loading size="small" />}
        {!playlistPitchesIsLoading && !isTracksLoading && tracks.length > 0 ? (
          <AccordionDetails>
            {!playlistPitchesIsLoading && (
              <PitchesTable
                tracks={tracks}
                pageNumber={pageNumber}
                playlistId={playlist.platform?.id}
                selectedTracks={selectedTracks}
                setSelectedTracks={setSelectedTracks}
                updateTracks={() => {
                  setIsTracksLoading(true);
                  setPageNumber(0);
                  refetchPlaylistPitches();
                }}
              />
            )}
            <div className="d-flex mt16">
              <Button
                disabled={pageNumber === 0}
                className="ml-auto icon-btn"
                onClick={() => {
                  setIsTracksLoading(true);
                  setPageNumber(pageNumber - 1);
                }}
              >
                <Icon>chevron_left</Icon>
              </Button>

              <Button
                disabled={pageNumber === totalPages - 1}
                className="icon-btn"
                onClick={() => {
                  setIsTracksLoading(true);
                  setPageNumber(pageNumber + 1);
                }}
              >
                <Icon>chevron_right</Icon>
              </Button>
            </div>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            {!isTracksLoading && (
              <div className="text-center mt16">
                <h4 data-testid="no-pitches-text">No Pitches available</h4>
              </div>
            )}
            {isTracksLoading && (
              <div className="centered-loading mt48">
                <Loading size="small" />
              </div>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
