import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import usePlaylistStats from '@/hooks/metrics/usePlaylistStats';

import PieChartComponent from '../charts/PieChart';
import ProgressBar from '../utility/ProgressBar';

type PlaylistPieData = {
  name: string;
  value: number;
  fill: string;
};

export default function PlaylistStatsCard() {
  const [playlistPieData, setPieData] = useState<PlaylistPieData[]>();
  const [userHasPlaylists, setUserHasPlaylists] = useState<boolean>(true);
  const { connectedPlaylists, acceptedPlaylists, pendingPlaylists, rejectedPlaylists } = usePlaylistStats();

  const navigate = useNavigate();

  useEffect(() => {
    if (connectedPlaylists === 0) setUserHasPlaylists(false);
    if (connectedPlaylists !== undefined) {
      setPieData([
        {
          name: 'Rejected',
          value: rejectedPlaylists || 0,
          fill: 'url(#pink-gradient)',
        },
        {
          name: 'Pending',
          value: pendingPlaylists || 0,
          fill: 'url(#yellow-gradient)',
        },
        {
          name: 'Verified',
          value: acceptedPlaylists || 0,
          fill: 'url(#blue-gradient)',
        },
      ]);
    }
  }, [acceptedPlaylists, connectedPlaylists, pendingPlaylists, rejectedPlaylists]);

  return (
    <>
      <div
        className="card playlists-connected-card cursor-pointer"
        onClick={() => {
          if (!userHasPlaylists) {
            navigate('/add-playlist');
          } else {
            navigate('/playlists');
          }
        }}
      >
        <div className="card-title">
          <div className="card-icon material-icons">
            <img src="/images/icons/PlaylistIcon.svg" alt=""></img>
          </div>

          <div className="titles">
            <div className="text-faded">Playlists connected</div>
            <div className="text-number">{connectedPlaylists}</div>
          </div>
          <Button
            className="icon-btn ml-auto"
            onClick={() => {
              navigate('/playlists');
            }}
          >
            <Icon>chevron_right</Icon>
          </Button>
        </div>
        <div className="pos-rel pt16 pb16">
          <PieChartComponent total={connectedPlaylists} playlistData={playlistPieData} />
          <p className="text-center text-faded ml-auto mr-auto mt16 mb32 max-w90p">
            You’ve connected <span className="text-white">{connectedPlaylists}</span> playlists
          </p>
          {playlistPieData && connectedPlaylists && (
            <div className="mb32">
              <ProgressBar
                title="Verified"
                label={playlistPieData[2].value}
                progress={(100 / connectedPlaylists) * playlistPieData[2].value}
              />
              <ProgressBar
                title="Pending"
                label={playlistPieData[1].value}
                progress={(100 / connectedPlaylists) * playlistPieData[1].value}
                color="yellow"
              />
              <ProgressBar
                title="Rejected"
                label={playlistPieData[0].value}
                progress={(100 / connectedPlaylists) * playlistPieData[0].value}
                color="pink"
              />
            </div>
          )}
          {!userHasPlaylists && (
            <div className="overlay-blur">
              <p className="text-faded mb16 mt100 pl16 pr16">
                It looks like you haven’t connected any of your playlists yet. Try to connect them now in order to
                receive pitches.
              </p>
              <Button
                onClick={() => {
                  navigate('/add-playlist');
                }}
                className="btn-white min-w180 m-auto"
              >
                <span className="btn-text icon-suffix">Add new playlist</span>
                <Icon>add</Icon>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
