import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Icon, Modal } from '@mui/material';

import HolidayMode from '@/components/utility/buttons/HolidayMode';
import usePlaylistPitches from '@/hooks/pitches/usePlaylistPitches';
import usePlaylist from '@/hooks/playlists/usePlaylist';

import FeedbackModal from '../components/modals/FeedbackModal';
import PitchesTable from '../components/tables/PitchesTable';
import Loading from '../components/utility/Loading';
import { PitchTrackModel } from '../models/TrackInterfaces';

export default function PlaylistPage() {
  const { slug } = useParams<string>();
  const navigate = useNavigate();
  const [selectedTracks, setSelectedTracks] = useState<PitchTrackModel[]>([]);
  const [tracks, setTracks] = useState<PitchTrackModel[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [loadMoreToken, setLoadMoreToken] = useState<string>();
  const [contToken, setContToken] = useState<string>();
  const [trackLoading, setTracksLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { playlist } = usePlaylist({ playlistId: slug || '' });
  const { playlistPitches, totalPlaylistPitches, playlistPitchesContToken, refetchPlaylistPitches } =
    usePlaylistPitches({
      playlistId: playlist?.platform.id || '',
      status: 'pending',
      contToken: loadMoreToken,
    });

  useEffect(() => {
    if (!playlistPitches) return;

    setTracks((tracks) => [...tracks, ...playlistPitches]);
    setTracksLoading(false);
    setIsLoadingMore(false);
    setIsInitialLoad(false);
  }, [playlistPitches]);

  useEffect(() => {
    if (playlistPitchesContToken) {
      setContToken(playlistPitchesContToken);
    }
  }, [playlistPitchesContToken]);

  const selectAll = () => {
    setSelectedTracks(tracks);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!totalPlaylistPitches || !playlistPitches || !playlistPitchesContToken) return false;
      return (
        entries[0].isIntersecting &&
        !isLoadingMore &&
        (entries[0].target as HTMLTableRowElement).rowIndex < totalPlaylistPitches
      );
    },
    [totalPlaylistPitches, playlistPitches, playlistPitchesContToken, isLoadingMore]
  );

  return (
    <div className="page-content">
      <div className="d-flex">
        <p
          className="text-faded cursor-pointer d-flex"
          onClick={() => {
            navigate('/playlists');
          }}
        >
          <Icon>chevron_left</Icon>
          <span className="pt2">Playlist pitches</span>
        </p>
        <div className="ml-auto mt-auto mb-auto">
          <HolidayMode playlist={playlist} />
        </div>
      </div>
      <div className="d-flex mt16">
        <img
          className="playlist-image"
          src={
            playlist?.images && playlist?.images[0]?.url
              ? playlist?.images[0].url
              : '/images/logos/no-image-available.svg'
          }
          alt=""
        ></img>
        <div className="pl16 flex-grow">
          <h2 data-testid="playlist-title">{playlist?.name}</h2>
          <div className="d-flex mt16" data-testid="playlist-followers">
            <p>
              Followers: <span className="text-faded">{playlist?.followers}</span>
            </p>
            <p className="pl16" data-testid="playlist-song-count">
              Song Count: <span className="text-faded">{playlist?.trackCount}</span>
            </p>
          </div>

          <p className="text-faded mt16">
            You can review all of your pending pitches below and decide whether you want to either accept or reject them
            onto your playlist.
          </p>
        </div>
      </div>
      <div className="mt16 d-flex">
        <h3 className="mt-auto mb-auto" data-testid="playlist-pending-pitches">
          Pending pitches: {totalPlaylistPitches}
        </h3>
        {tracks?.length > 0 && (
          <div className="ml-auto d-flex">
            {selectedTracks?.length !== tracks?.length && (
              <Button onClick={selectAll} data-testid="select-all-button" className="mr0">
                Select all
              </Button>
            )}
            {selectedTracks?.length === tracks?.length && (
              <Button
                onClick={() => {
                  setSelectedTracks([]);
                }}
                className="mr0"
                data-testid="deselect-all-button"
              >
                Deselect all
              </Button>
            )}
          </div>
        )}
      </div>
      {!trackLoading && tracks?.length > 0 && (
        <div className={selectedTracks.length === tracks?.length ? 'to-do-card-border mt16 mb100' : 'card mt16 mb100'}>
          <div className={selectedTracks.length === tracks.length ? 'to-do-card' : 'p1'}>
            <PitchesTable
              tracks={tracks}
              playlistId={playlist?.id}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
              updateTracks={() => {
                setTracksLoading(true);
                setTracks([]);
                setLoadMoreToken('');
                setSelectedTracks([]);
                refetchPlaylistPitches();
              }}
              bottomTrackIntersecting={(entries) => {
                if (canPage(entries)) {
                  setIsLoadingMore(true);
                  setLoadMoreToken(contToken);
                } else {
                  setIsLoadingMore(false);
                }
              }}
            />
            {isLoadingMore && <div className="text-center mt16">Loading more...</div>}
          </div>
        </div>
      )}
      {!trackLoading && tracks?.length === 0 && !playlist?.statusInfo.isPaused && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-pitches-available">No Pitches available</h4>
        </div>
      )}
      {playlist?.statusInfo.isPaused && (
        <div className="text-center title-card mt16">
          <h4 data-testid="holiday-mode">Turn off holiday mode 🏝️ to start receiving pitches to this playlist again</h4>
        </div>
      )}
      {trackLoading && isInitialLoad && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}

      {selectedTracks.length > 0 && (
        <div className="playlist-select-footer d-flex">
          <div className="mt-auto mb-auto">
            {' '}
            <p>
              {selectedTracks.length} {selectedTracks.length === 1 ? 'pitch' : 'pitches'} selected
            </p>
          </div>
          <div className="ml-auto">
            <Button
              className="border-btn"
              onClick={() => {
                setAccept(false);
                setModalOpen(true);
              }}
            >
              <span className="btn-text icon-suffix">Reject</span>
              <Icon>close</Icon>
            </Button>
            <Button
              onClick={() => {
                setAccept(true);
                setModalOpen(true);
              }}
              className="btn-white"
            >
              <span className="btn-text icon-suffix">Accept</span>
              <Icon>done</Icon>
            </Button>
          </div>
        </div>
      )}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={selectedTracks}
            accept={accept}
            closeModalOutput={setModalOpen}
            updateTracks={() => {
              setTracksLoading(true);
              setTracks([]);
              setLoadMoreToken('');
              setSelectedTracks([]);
              refetchPlaylistPitches();
            }}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </div>
  );
}
