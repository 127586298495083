import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { UnhurdPlaylist } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccount from '../useAccount';

const usePlaylist = ({ playlistId }: { playlistId: string }) => {
  const { unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<UnhurdPlaylist> | null>(
    () => {
      if (!unhurdUser || !playlistId) return null;

      return `playlist-data-${playlistId}`;
    },
    async () => {
      if (!unhurdUser || !playlistId) return null;

      return await PlaylistAPI.getPlaylist({
        id: playlistId,
        spotifyId: unhurdUser?.spotifyAccounts[0].id,
      });
    }
  );

  return {
    playlist: data?.data,
    playlistIsLoading: isLoading,
    playlistError: error,
    refetchPlaylist: mutate,
  };
};

export default usePlaylist;
