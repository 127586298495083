import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SortIcon from '@mui/icons-material/Sort';
import { Button, Icon } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StatusBadges from '@/components/utility/statuses/StatusBadges';
import usePendingPitchesStats from '@/hooks/metrics/usePendingPitchesStats';
import usePlaylists from '@/hooks/playlists/usePlaylists';
import { ColumnsModel, UnhurdPlaylist } from '@/models/PlaylistInterface';

import Loading from '../components/utility/Loading';

export default function PlaylistsPage() {
  const [userPlaylists, setUserPlaylists] = useState<UnhurdPlaylist[]>([]);
  const [isLoadingPlaylists, setIsLoadingPlaylists] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('pending-pitches');
  const [order, setOrder] = useState<'desc' | 'asc'>('desc');
  const { playlists, totalPages, totalPlaylists, refetchPlaylists } = usePlaylists({
    pageNumber: pageNumber,
    sortBy: sortBy,
    orderBy: order,
    status: '',
  });
  const { pendingPitches } = usePendingPitchesStats();

  const ref = useRef<HTMLTableRowElement>(null);

  const navigate = useNavigate();
  document.title = 'Playlists';

  const columns: ColumnsModel[] = [
    { name: '#', id: 'number' },
    { name: 'Playlist Title', id: 'title' },
    { name: 'Song Count', id: 'track-count' },
    { name: 'Status', id: 'status' },
    { name: 'No. of pending pitches', id: 'pending-pitches' },
  ];

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!totalPages || !playlists || !totalPlaylists) return;
      return (
        entries[0].isIntersecting &&
        !isLoadingMore &&
        (entries[0].target as HTMLTableRowElement).rowIndex < totalPlaylists
      );
    },
    [totalPages, playlists, isLoadingMore, totalPlaylists]
  );

  useEffect(() => {
    if (!playlists) return;
    setUserPlaylists((value) => [...value, ...playlists]);
    setIsLoadingMore(false);
    setIsLoadingPlaylists(false);
  }, [playlists]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      if (canPage(entries)) {
        setIsLoadingMore(true);
        setPageNumber(pageNumber + 1);
      } else {
        setIsLoadingMore(false);
      }
    }, {});
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [canPage, pageNumber, totalPages, totalPlaylists, userPlaylists]);

  return (
    <div className="page-content">
      <div className="d-flex jc-space-between mb16">
        <h2 data-testid="your-playlist-title">Your playlists</h2>
        <Button
          onClick={() => {
            navigate('/add-playlist');
          }}
          className="btn-white min-w180 mr0"
          data-testid="add-new-playlist-button"
        >
          <span className="btn-text icon-suffix">Add new playlist</span>
          <Icon>add</Icon>
        </Button>
      </div>
      <div className="top-cards">
        <div className="title-card">
          <div className="card-title">
            <div className="card-icon material-icons">
              <img src="/images/icons/PlaylistIcon.svg" alt=""></img>
            </div>
            <div className="titles">
              <div className="text-faded">Playlists connected</div>
              <div className="text-number">{totalPlaylists}</div>
            </div>
          </div>
        </div>
        <div className="title-card">
          <div className="card-title">
            <div className="card-icon material-icons">
              <img src="/images/icons/PitchingIcon.svg" alt=""></img>
            </div>
            <div className="titles">
              <div className="text-faded">Pending pitches</div>
              <div className="text-number">{pendingPitches}</div>
            </div>
          </div>
        </div>
      </div>
      {!isLoadingPlaylists && userPlaylists && userPlaylists.length > 0 && (
        <div className="playlist-card mt16">
          <div className="d-flex">
            <h3>Connected playlists</h3>
            <Button
              className="icon-btn mt0 ml-auto"
              onClick={() => {
                setIsLoadingPlaylists(true);
                setUserPlaylists([]);
                setPageNumber(1);
                refetchPlaylists();
              }}
            >
              <Icon>refresh</Icon>
            </Button>
          </div>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col width="1%" />
                <col width="30%" />
                <col width="10%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} data-testid={`column-name-${column.id}`}>
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={order}
                        onClick={() => {
                          setUserPlaylists([]);
                          setSortBy(column.id);
                          setOrder(column.id === sortBy ? (order === 'desc' ? 'asc' : 'desc') : order);
                          setIsLoadingPlaylists(true);
                        }}
                        IconComponent={SortIcon}
                      >
                        <div className={column.name === '#' ? 'pl16' : ''}>{column.name}</div>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userPlaylists?.map((row, index) => (
                  <TableRow
                    key={index}
                    data-testid={`playlist-table-item-${index}`}
                    ref={index === userPlaylists.length - 1 ? ref : null}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '--animation-number': index % 20,
                    }}
                  >
                    <TableCell className="pl16" width={5}>
                      {index + 1}
                    </TableCell>
                    <TableCell width="30%">
                      <div className="table-item-with-image">
                        <img
                          src={row.images?.length > 0 ? row.images[0]?.url : 'images/logos/no-image-available.svg'}
                          alt=""
                        />
                        <span>{row.name}</span>
                      </div>
                    </TableCell>
                    <TableCell>{row.trackCount}</TableCell>

                    <TableCell>
                      <StatusBadges status={row.statusInfo?.status} isPaused={row.statusInfo.isPaused} />
                    </TableCell>

                    <TableCell>
                      <div className="table-end-call-with-button">
                        <span>{row.stats?.pendingPitches || 0}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="d-flex">
                        <Button
                          onClick={() => {
                            navigate(`/playlists/${row.id}`);
                          }}
                          className="btn-white ml-auto mr0"
                          data-testid={`playlist-table-item-${index}-button`}
                        >
                          <span className="btn-text icon-suffix">Review</span>
                          <Icon>chevron_right</Icon>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoadingMore && <div className="text-center">Loading more...</div>}
        </div>
      )}

      {!isLoadingPlaylists && userPlaylists && userPlaylists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4>You've not connected any playlists yet</h4>
        </div>
      )}
      {isLoadingPlaylists && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </div>
  );
}
