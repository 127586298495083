import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistPitchesAPIModel } from '@/models/TrackInterfaces';
import PitchesAPI from '@/network/PitchesAPI';

import useAccount from '../useAccount';

const usePlaylistPitches = ({
  playlistId,
  status,
  pageSize,
  contToken,
}: {
  playlistId: string;
  status: string;
  pageSize?: number;
  contToken?: string;
}) => {
  const { unhurdUser } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistPitchesAPIModel> | null>(
    () => {
      if (!unhurdUser || !playlistId || playlistId === '') return null;

      return `playlist-pitches-data-${playlistId}-${status}-${contToken}-${pageSize ?? 20}`;
    },
    async () => {
      if (!unhurdUser || !playlistId || playlistId === '') return null;

      return await PitchesAPI.getPlaylistPitches({
        playlistId: playlistId,
        pageSize: pageSize ?? 20,
        status: status,
        contToken: contToken,
      });
    }
  );

  return {
    playlistPitches: data?.data.items,
    totalPlaylistPitches: data?.data.total,
    playlistPitchesContToken: data?.data.continuationToken,
    playlistPitchesIsLoading: isLoading,
    playlistPitchesError: error,
    refetchPlaylistPitches: mutate,
  };
};

export default usePlaylistPitches;
