import React, { useContext, useEffect, useState } from 'react';

import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Icon from '@mui/material/Icon';
import { AxiosError } from 'axios';

import useSpotifyPlaylists from '@/hooks/playlists/useSpotifyPlaylists';
import useAccount from '@/hooks/useAccount';
import { ErrorType } from '@/models/Errors';
import { AddPlaylistsPlaylistsModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';
import UserAPI from '@/network/UserAPI';

import AppContext, { SnackbarContextModel } from '../../contexts/AppContext';
import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';
import Loading from '../utility/Loading';

export default function ChoosePlaylists() {
  const { unhurdUser } = useAccount();
  const [playlists, setPlaylists] = useState<AddPlaylistsPlaylistsModel[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);
  const { connectedSpotifyPlaylists, unconnectedSpotifyPlaylists, spotifyPlaylistsIsLoading, spotifyPlaylistsError } =
    useSpotifyPlaylists();

  useEffect(() => {
    if (spotifyPlaylistsError) {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: "We can't find your playlists on Spotify - check you have made a playlist and refresh this page",
          type: 'error',
        },
      });
    }
  }, [dispatchSnackbar, spotifyPlaylistsError]);

  const handleCheckboxChange = (event: AddPlaylistsPlaylistsModel) => {
    const result = playlists.filter((item) => item === event);
    if (result.length > 0) {
      const checked = playlists.filter((item) => item !== event);
      setPlaylists(checked);
    } else {
      setPlaylists([...playlists, event]);
    }
  };

  const submitPlaylists = async () => {
    setIsSubmitting(true);
    if (!unhurdUser) return;
    if (playlists.length > 0) {
      const data = {
        playlistsToConnect: playlists,
      };
      try {
        await PlaylistAPI.addPlaylists(data);
      } catch (error: AxiosError | unknown) {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message:
              (error as AxiosError<ErrorType>).response?.data?.errorMessage || 'Oops, something went wrong, try again',
            type: 'error',
          },
        });
        setIsSubmitting(false);
        return;
      }
    }
    const details = {
      userId: unhurdUser?.userId,
      id: unhurdUser?.id,
      onboardingStage: 3,
    };
    UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details })
      .then(() => {
        dispatch({
          type: 'PAGE NEXT',
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Oops, something went wrong, try again',
            type: 'error',
          },
        });
        setIsSubmitting(false);
      });
  };

  const doLaterButton = async () => {
    if (!unhurdUser) return;
    const details = {
      userId: unhurdUser?.userId,
      id: unhurdUser?.id,
      onboardingStage: 3,
    };
    UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details }).then(() => {
      dispatch({
        type: 'PAGE NEXT',
      });
    });
  };

  const isChecked = (event: AddPlaylistsPlaylistsModel) => {
    const result = playlists.filter((item) => item === event);
    return result.length > 0;
  };

  return (
    <div>
      <h1 className="">Choose your playlists</h1>
      <h3 className="text-faded mt32">
        Choose your playlists from your Spotify account that you wish to connect to un:hurd music. Once you have
        connected these playlists, you can receive pitches from different artists.
      </h3>
      <h3 className="mt48 text-left">Verified playlists</h3>
      {!spotifyPlaylistsIsLoading && connectedSpotifyPlaylists?.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">You don't have any connected playlists yet.</h4>
        </div>
      )}
      {!spotifyPlaylistsIsLoading ? (
        <div className="playlists-container mt32">
          {connectedSpotifyPlaylists?.map((item: AddPlaylistsPlaylistsModel, index) => (
            <div
              key={item.spotifyId}
              className="playlist-item is-connected"
              style={{ '--animation-number': `${index}` } as React.CSSProperties}
            >
              <div className="playlist-item-checkbox-container">
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      id="playlist-checkbox"
                      name="playlist-checkbox"
                      icon={<div className="checkbox-icon"></div>}
                      checked={item.isConnected}
                      checkedIcon={
                        <div className="checkbox-icon">
                          <div className="checked"></div>
                        </div>
                      }
                      value={item.id}
                    />
                  }
                />
              </div>
              <img
                src={item.image?.url ? item.image?.url : '/images/logos/no-image-available.svg'}
                alt="playlist-img"
              />
              <div className="playlist-titles">
                <div className={`title  ${item.isConnected ? 'text-blue-gradient' : ''}`}>
                  {item.name ? item.name : 'No title'}
                </div>
                <div className="followers text-faded">Tracks: {item.totalTracks}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <h3 className="mt48 text-left">Unselected playlists</h3>
      {!spotifyPlaylistsIsLoading && unconnectedSpotifyPlaylists?.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">You don't have any playlists to connect</h4>
        </div>
      )}
      {!spotifyPlaylistsIsLoading ? (
        <div className="playlists-container mt32 mb4rem">
          {unconnectedSpotifyPlaylists?.map((item: AddPlaylistsPlaylistsModel, index) => (
            <div
              key={item.spotifyId}
              className="playlist-item cursor-pointer"
              style={{ '--animation-number': `${index}` } as React.CSSProperties}
              onClick={() => {
                handleCheckboxChange(item);
              }}
            >
              <div className="playlist-item-checkbox-container">
                <Checkbox
                  id="playlist-checkbox"
                  name="playlist-checkbox"
                  icon={<div className="checkbox-icon"></div>}
                  checked={isChecked(item)}
                  checkedIcon={
                    <div className="checkbox-icon">
                      <div className="checked"></div>
                    </div>
                  }
                  onChange={() => {
                    handleCheckboxChange(item);
                  }}
                  value={item.id}
                />
              </div>
              <img
                src={item.image?.url ? item.image?.url : '/images/logos/no-image-available.svg'}
                alt="playlist-img"
              />
              <div className="playlist-titles">
                <div className={`title  ${item.isConnected ? 'text-blue-gradient' : ''}`}>
                  {item.name ? item.name : 'No title'}
                </div>
                <div className="followers text-faded">Tracks: {item.totalTracks}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className="do-later-button">
        <Button
          disabled={isSubmitting}
          className="mt32"
          onClick={() => {
            doLaterButton();
          }}
          data-testid="do-it-later-button"
        >
          <span className="btn-text">Do this later</span>
        </Button>
      </div>

      <div className="continue-button">
        <Button
          disabled={isSubmitting}
          className="mt32 btn-white"
          onClick={() => {
            submitPlaylists();
          }}
          data-testid="continue-button"
        >
          <span className="btn-text icon-suffix">Continue</span>
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </div>
  );
}
